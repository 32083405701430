import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-hooks";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "styled-components";
import { apolloClient } from "common";
import App from "./App";
import i18n from "./i18n";
import reportWebVitals from "./reportWebVitals";
import theme from "./styles/theme";

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <Suspense fallback="loading...">
        <ThemeProvider theme={theme}>
          <ApolloProvider client={apolloClient}>
            <App />
          </ApolloProvider>
        </ThemeProvider>
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
