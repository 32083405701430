import { Redirect, Route } from "react-router-dom";
const ProtectedRoute = ({
  path,
  isAuthenticated,
  component: Component,
  redirectTo = "/sign-in",
  ...rest
}) => (
  <Route
    {...rest}
    path={path}
    render={(props) => {
      if (isAuthenticated) {
        return <Component {...props} />;
      }
      return <Redirect to={redirectTo} />;
    }}
  />
);

export default ProtectedRoute;
