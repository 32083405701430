import { BrowserRouter as Router, Route } from "react-router-dom";
import { StyleSheetManager } from "styled-components";
import rtlPlugin from "stylis-plugin-rtl";

import "./App.css";

import {
  useAuth,
  useLocalization,
  LocalizationProvider,
  GlobalStyles,
  AuthProvider,
  ProtectedRoute,
} from "common";

import SignIn from "./components/pages/auth/SignIn";
import Dashboard from "./components/pages/dashboard/Dashboard";
import { Home } from "./components/pages/home/Home";

function App() {
  const localization = useLocalization();
  const auth = useAuth();

  const rtl = localization.rtl;

  return (
    <div dir={rtl ? "rtl" : "ltr"}>
      <StyleSheetManager stylisPlugins={rtl ? [rtlPlugin] : []}>
        <AuthProvider value={auth}>
          <GlobalStyles />
          <LocalizationProvider value={localization}>
            <Router>
              <Route path="/sign-in" component={SignIn} />
              <Route path="/" exact component={Home} />
              <ProtectedRoute
                path="/dashboard"
                exact
                component={Dashboard}
                isAuthenticated={auth.isAuth}
              />
            </Router>
          </LocalizationProvider>
        </AuthProvider>
      </StyleSheetManager>
    </div>
  );
}

export default App;
