import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

import GilroyExtraBold from "../fonts/Gilroy-ExtraBold.otf";
import GilroyLight from "../fonts/Gilroy-Light.otf";
import InterBlack from "../fonts/Inter-Black.ttf";
import InterBold from "../fonts/Inter-Bold.ttf";
import InterExtraBold from "../fonts/Inter-ExtraBold.ttf";
import InterExtraLight from "../fonts/Inter-ExtraLight.ttf";
import InterLight from "../fonts/Inter-Light.ttf";
import InterMedium from "../fonts/Inter-Medium.ttf";
import InterRegular from "../fonts/Inter-Regular.ttf";
import InterSemiBold from "../fonts/Inter-SemiBold.ttf";
import InterThin from "../fonts/Inter-Thin.ttf";

const GlobalStyles = createGlobalStyle`
  ${normalize}
  a {
    text-decoration: none;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  body {
  }
  html {
    font-size: 62.5%;
  }
  ul {
    list-style-type: none;
  }
  @font-face {
    font-family: gilrov-eb;
    src:  url(${GilroyExtraBold});
  }
  @font-face {
    font-family: gilrov-l;
    src:  url(${GilroyLight});
  }
  @font-face {
    font-family: inter-black;
    src:  url(${InterBlack});
  }
  @font-face {
    font-family: inter-b;
    src:  url(${InterBold});
  }
  @font-face {
    font-family: inter-eb;
    src:  url(${InterExtraBold});
  }
  @font-face {
    font-family: inter-el;
    src:  url(${InterExtraLight});
  }
  @font-face {
    font-family: inter-l;
    src:  url(${InterLight});
  }
  @font-face {
    font-family: inter-m;
    src:  url(${InterMedium});
  }
  @font-face {
    font-family: inter-r;
    src:  url(${InterRegular});
  }
  @font-face {
    font-family: inter-sb;
    src:  url(${InterSemiBold});
  }
  @font-face {
    font-family: inter-t;
    src:  url(${InterThin});
  }

  input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}
`;
export default GlobalStyles;
