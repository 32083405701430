import { useEffect, useState } from "react";

const countryLanguage = {
  il: "heb",
};
const rtlLanguages = ["heb"];

const useLocalization = () => {
  const [locale, setLocale] = useState(() =>
    JSON.parse(localStorage.getItem("locale"))
  );

  useEffect(() => {
    if (!locale) {
      fetch(process.env.REACT_APP_IP_API)
        .then((res) => res.json())
        .then((data) => {
          const loc = {
            language: countryLanguage[data.countryCode.toLowerCase()] || "en",
            country: data.country,
            countryCode: data.countryCode,
          };
          setLocale(loc);
          localStorage.setItem("locale", JSON.stringify(loc));
        })
        .catch((err) => {
          localStorage.setItem("locale", JSON.stringify({ language: "en" }));
        });
    }
  }, []);

  return {
    ...locale,
    rtl: !!rtlLanguages.includes(locale?.language),
  };
};

export default useLocalization;
