import { useEffect, useState } from "react";
import { meQuery } from "gql";
import { useQuery } from "@apollo/react-hooks";

const useAuth = () => {
  const [user, setUser] = useState(null);
  const [hasToken, setHasToken] = useState(
    () => !!localStorage.getItem("token")
  );

  const { data, loading, refetch } = useQuery(meQuery, {
    fetchPolicy: "no-cache",
    skip: !hasToken,
  });

  useEffect(() => {
    if (data?.me) {
      setUser(data.me);
    }
  }, [data]);

  const setToken = (token) => {
    localStorage.setItem("token", token);
    setHasToken(true);
  };

  const removeToken = () => {
    localStorage.removeItem("token");
    setHasToken(false);
  };

  return {
    setToken,
    removeToken,
    me: data && data.me ? data.me : user || undefined,
    meLoading: loading,
    meRefetch: refetch,
    setUser: (user) => setUser(user),
    hasToken,
  };
};

export default useAuth;
